import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import * as blogTemplateStyles from "../styles/templates/blog.module.scss"
import Noise from "../components/Noise"
import Img from "gatsby-image"

export default function Blog(props) {
  const data = props.data.markdownRemark

  return (
    <Layout>
      <div className={blogTemplateStyles.background}>
        <Noise opacity={0.085} />
      </div>
      <article className={blogTemplateStyles.blog}>
        <figure className={blogTemplateStyles.blog__hero}>
          <Noise opacity={data.frontmatter.noise_opacity} />
          <Img fluid={data.frontmatter.hero_image.childImageSharp.fluid} />
        </figure>
        <Container className={blogTemplateStyles.blog__content}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 10, offset: 1 }}
            >
              <div className={blogTemplateStyles.blog__info}>
                <Link to={`/`}>Back</Link>
                <h1>{data.frontmatter.title}</h1>
                <h3>{data.frontmatter.date}</h3>
              </div>
              <div
                className={blogTemplateStyles.blog__body}
                dangerouslySetInnerHTML={{ __html: data.html }}
              ></div>
            </Col>
          </Row>
        </Container>
      </article>
    </Layout>
  )
}

//dynamic page query, must occur within each post context
//$slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        hero_image {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        noise_opacity
      }
      html
    }
  }
`
